import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from '../components/global/BaseLayout';


function Payment() {
    const { rpdata } = useContext(GlobalDataContext);

    
    return (
        <BaseLayout PageName="Payment Methods">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Payment Methods"
                    Subheader="Payment Methods"
                    bgimg={`${rpdata?.stock?.[2]}`}
                    svgClass='svgTranspareHeader2'

                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10">Payment Methods</h1>
                <div className="flex flex-col items-center w-full">

                    <div className="w-full">
                        {
                            rpdata?.tiposPago?.[0]?.activo ?
                                <picture>
                                    <img
                                        src={rpdata?.tiposPago?.[0]?.img}
                                        alt='payment method'
                                        className="w-[350px] h-[60px] mx-auto"
                                    />
                                </picture>
                                : null
                        }
                    </div>

                    <div className="w-1/2 h-[60px] rounded-3xl flex justify-center items-center cursor-pointer space-x-4">

                        <a target="_blank" rel="noreferrer" href="https://dd460893-2078-4221-b5d0-3659fea1b0ce.paylinks.godaddy.com/bestlandscapingllc" className="h-full w-[30%] rounded-2xl bg1 flex items-center justify-center hover:scale-110" >
                            <span className="text-white text-center font-semibold text-2xl">Pay Now!!</span>
                        </a>
                        


                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Payment